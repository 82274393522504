$(function () {
    $('.flag-check').each(function () {
        if($(this).is(':checked')) {
            $(this).parent().attr('class', 'flag-on');
        }
    });

    $('.show-details:checked').each(function () {
        if('Yes' == $(this).val()) {
            var show = $(this).data('show');

            $('#' + show).show();
        }
    });
})

$('.show-details').on('click', function () {
    var show = $(this).data('show');

    if ($(this).val() == 'Yes') {
        $('#' + show).slideDown();
    } else {
        $('#' + show).slideUp();
        clearFormElements('#' + show);
    }
});

// Clear the values within a div with a given class name
function clearFormElements(className)
{
    $(className).find(':input').each(function() {
        switch(this.type) {
            case 'password':
            case 'text':
            case 'textarea':
            case 'file':
            case 'select-one':
            case 'select-multiple':
				$(this).val('');
				break;
            case 'checkbox':
            case 'radio':
                this.checked = false;
        }
    });
}

function calculateProportion(count, total)
{
    if (typeof(count) == "undefined" || '' == count) {
        count = 0;
    }

    if (typeof(total) == "undefined" || '' == total) {
        total = 0;
    }

    if (total == 0) {
        return 100;
    }

    var proportion = (count/total)*100;

    return Math.round(proportion);
}